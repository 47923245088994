import React from 'react';
import { Text, Checkbox } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Block, BlockAlign } from '../../../../../common/components/Block';
import { classes, st } from './AddMembersModal.st.css';
import { Button } from '../../../../../common/components/Button';
import { Loader } from '../../../../../common/components/Loader';
import { ADD_MEMBERS_HOOK } from './AddMembersModal';
import { Theme, withTheme } from '../../../../../common/context/theme';

interface Props {
  selectedCount: number;
  totalCount: number;
  updating: boolean;
  selectAll: boolean;
  showCheckbox: boolean;
  onSelectAll(): void;
  onAdd(): void;
}

const AddMembersBarComponent: React.FC<Props> = ({
  selectedCount,
  totalCount,
  updating,
  selectAll,
  onSelectAll,
  onAdd,
  showCheckbox,
  forceBlackAndWhite,
}: Props & Theme) => {
  const { t } = useTranslation();
  if (!totalCount) {
    return null;
  }
  return (
    <Block
      justify={BlockAlign.start}
      align={BlockAlign.center}
      className={st(classes.selectAll, {
        withButton: !!selectedCount,
        notEmptySearch: !showCheckbox,
      })}
    >
      {showCheckbox ? (
        <Checkbox
          className={classes.selectAllCheckbox}
          checked={selectAll}
          indeterminate={!selectAll && !!selectedCount}
          onChange={onSelectAll}
        />
      ) : null}
      <Text className={classes.selectedCount}>
        {!showCheckbox || !!selectedCount
          ? t('group-web.add.members.selected', { count: selectedCount })
          : t('group-web.add.members.select-all', {
              count: totalCount,
            })}
      </Text>
      {selectedCount ? (
        <Button
          disabled={updating}
          prefixIcon={updating ? <Loader /> : undefined}
          onClick={onAdd}
          data-hook={ADD_MEMBERS_HOOK}
          bw={forceBlackAndWhite}
        >
          {t('groups-web.add')}
        </Button>
      ) : null}
    </Block>
  );
};
export const AddMembersBar = withTheme(
  AddMembersBarComponent,
) as React.ComponentType<Props>;
AddMembersBar.displayName = 'AddMembersBar';
