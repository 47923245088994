import {
  WixRicosEditorProps,
  WixRicosViewerProps,
} from '@wix/ricos-common/dist/types/WixRicosTypes';
import { Theme } from '../../../../common/context/theme';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { ViewerPlugin } from 'wix-rich-content-common';

export enum PRESETS {
  VIEWER = 'viewer',
  EDITOR = 'editor',
}

export interface RichContentProps {
  preset?: PRESETS;
  className?: string;
  contentId?: 'custom_tab' | 'about_group' | string;
}

export interface RichContentViewerProps
  extends Omit<WixRicosViewerProps, 'biSettings' | 'instance'>,
    RichContentProps {
  handleUserValidationRequest?(resolve: Function, reject: Function): void;
  disablePreview?: boolean;
  showSeeMore?: boolean;
  usage: 'About' | 'FeedItem' | 'CustomTab';
}

export interface RichContentEditorProps
  extends WixRicosEditorProps,
    Theme,
    RichContentProps {
  autoFocus?: boolean;
  usage: 'About' | 'NewPostModal' | 'CustomTab';

  initEditorEvents?(editorEvents: any, events: any): void;
  forwardRef?: any;
  parentClass?: string;
}

// do NOT import from ricos -> increase bundle size
export enum VerticalEmbedProviders {
  Event = 'event',
  Booking = 'booking',
  Product = 'product',
}

// do NOT import from ricos -> increase bundle size
export enum LinkPreviewProviders {
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  YouTube = 'YouTube',
  TikTok = 'TikTok',
}

// do NOT import from ricos -> increase bundle size
export enum VideoProviders {
  Video = 'video',
  SoundCloud = 'soundCloud',
  YouTube = 'youTube',
}

export type PluginMap = {
  [key in PluginTypes]?: (config: any) => ViewerPlugin;
};
