import React, { useState } from 'react';
import * as FeedTypes from 'FeedWidget/api/types';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { ThreeDots } from '../../ThreeDots/ThreeDots';
import { DeletePostModal } from '../../Modals/DeletePostModal/DeletePostModal';
import { NewPostModal } from '../../Modals/NewPostModal/NewPostModal';
import { useFeedBI } from '../../hooks/useFeedBI';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useGroupMember } from '../../hooks/useGroupMember';

interface PostActionsProps {
  itemId: string;
  topicId?: string;
  contentState: RawDraftContentState<any>;

  isPinnedPost: boolean;
  isActivityPost: boolean;
  isFollowedPost: boolean;
  showShareButton: boolean;

  deletePost(): void;

  pinPost(): void;

  unpinPost(): void;

  followPost(): void;

  unfollowPost(): void;

  updatePost(content: RawDraftContentState<any>, attachments?: any): void;

  createdBy: FeedTypes.IGroupMember;

  onShare(): void;

  iconClassName?: string;
}

interface PostActionsState {
  isActionsMenuOpened: boolean;
  isDeletePostModalOpened: boolean;
  isShareDialogOpen: boolean;
  inEditMode: boolean;
}

enum Dialog {
  NONE,
  MENU,
  DELETE,
  SHARE,
  NEW_POST,
}

type Props = PostActionsProps;

export const PostActions: React.FC<Props> = (props) => {
  const { contentState, iconClassName, topicId, itemId } = props;
  const [isOpen, setOpen] = useState<Dialog>(Dialog.NONE);
  const { isMe, groupMember } = useGroupMember();
  const bi = useFeedBI(props.itemId);
  const { t } = useTranslation();
  const closeDialog = () => {
    setOpen(Dialog.NONE);
  };

  const handleDeletePost = () => {
    props.deletePost();
    closeDialog();
    bi.postActionClick({
      action: 'delete',
    });
  };

  const handleUpdatePost = (
    content: RawDraftContentState<any>,
    updatedTopicId?: string,
  ) => {
    props.updatePost(content, updatedTopicId);
    closeDialog();

    if (updatedTopicId) {
      bi.groupFeedTopicsAddTopicToPost({ topicName: updatedTopicId });
    }
  };

  const handlePinUnpinPost = () => {
    const { pinPost, unpinPost, isPinnedPost } = props;
    isPinnedPost ? unpinPost() : pinPost();
    closeDialog();
    bi.postActionClick({ action: isPinnedPost ? 'unpin' : 'pin' });
  };

  const handleFollowUnfollowPost = () => {
    const { followPost, unfollowPost, isFollowedPost } = props;
    isFollowedPost ? unfollowPost() : followPost();
    closeDialog();
    bi.postActionClick({ action: isFollowedPost ? 'unfollow' : 'follow' });
  };

  const handleShareClick = () => {
    const { onShare } = props;
    onShare();
    bi.postActionClick({ action: 'share' });
  };
  const openEditMode = () => {
    setOpen(Dialog.NEW_POST);
    bi.postActionClick({ action: 'edit' });
  };

  const openDeletePostPopup = () => setOpen(Dialog.DELETE);

  const renderMenuItems = () => {
    const {
      createdBy,
      isPinnedPost,
      isFollowedPost,
      isActivityPost,
      showShareButton,
    } = props;
    const items = [];

    const isMyPost = isMe(createdBy.siteMemberId);
    const isAdmin = groupMember.isAdmin();
    const canDeletePost = isAdmin || isMyPost;
    const canPinUnpinPost = isAdmin;
    const canFollow = true;
    const canEditPost = isMyPost && !isActivityPost;
    const canSharePost = showShareButton && groupMember.canShare();

    canPinUnpinPost &&
      items.push({
        onClick: handlePinUnpinPost,
        content: t(
          isPinnedPost
            ? 'groups-web.discussion.feed.post-actions.unpin-post'
            : 'groups-web.discussion.feed.post-actions.pin-post',
        ),
      });

    canFollow &&
      items.push({
        onClick: handleFollowUnfollowPost,
        content: t(
          isFollowedPost
            ? 'groups-web.discussion.feed.post-actions.following'
            : 'groups-web.discussion.feed.post-actions.follow',
        ),
      });

    canSharePost &&
      items.push({
        onClick: handleShareClick,
        content: t('groups-web.discussion.feed.post-actions.share'),
      });

    canEditPost &&
      items.push({
        onClick: openEditMode,
        content: t('groups-web.discussion.feed.post-actions.edit'),
      });

    canDeletePost &&
      items.push({
        onClick: openDeletePostPopup,
        content: t('groups-web.discussion.feed.post-actions.delete'),
      });

    return items;
  };

  const toggleActionsMenu = () => {
    setOpen(isOpen === Dialog.MENU ? Dialog.NONE : Dialog.MENU);
  };
  const inEditMode = isOpen === Dialog.NEW_POST;

  return (
    <div>
      <ThreeDots
        onClose={closeDialog}
        isOpen={isOpen === Dialog.MENU}
        onOpen={toggleActionsMenu}
        items={renderMenuItems()}
        iconClassName={iconClassName}
      />
      <DeletePostModal
        isOpen={isOpen === Dialog.DELETE}
        onClose={closeDialog}
        onDelete={handleDeletePost}
      />
      {inEditMode ? (
        <NewPostModal
          isOpen
          isPostPublishing={false}
          onSubmit={handleUpdatePost}
          initialContentState={contentState}
          topicId={topicId}
          feedItemId={itemId}
          discardPost={closeDialog}
        />
      ) : null}
    </div>
  );
};

PostActions.displayName = 'PostActions';
