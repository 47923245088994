import React from 'react';

import { Tabs, TabsAlignment, TabsVariant } from 'wix-ui-tpa';

import {
  ApiTypesV1GroupResponse,
  GroupAppKey,
  GroupAppKeyToTab,
  GroupApps,
  GroupAppsMap,
} from '@wix/social-groups-api';

import { classes, st } from './Group.st.css';

import { SiteNavigation } from '../../controllers/main/SiteNavigation';
import { TabsUrls } from '../../controllers/main/MainControllerProps';
import {
  withAppData,
  WithAppDataProps,
} from '../../contexts/AppData/withAppData';
import { WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import { WithAppToastsProps } from '../../../../common/components/AppToats/withAppToastsProps';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../contexts/GroupActions/WithGroupActions';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../contexts/TPAComponent/withTpaComponentsConfig';
import { ModalParentNodeRefProvider } from '../../../../common/components/Modal/ModalParentNodeRefContext';
import { AppToasts } from '../../../../common/components/AppToats/AppToasts';
import { Breadcrumbs } from '../../../../common/components/Breadcrumbs/Breadcrumbs';
import { Header } from '../Header/Header';
import { Loading } from '../../../../common/components/Loader';
import { TABS_HOOK } from './dataHooks';
import { compose } from '../../../../common/utils/compose';
import { withAppToasts } from '../../../../common/components/AppToats/withAppToasts';
import { Page } from '../Page/Page';
import { Pages } from '../Page/Pages';
import { AboutPage } from '../About/AboutPage';
import { Media } from '../Media/Media';

import Discussion from '../Discussion/Discussion';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';
import { Events } from '../Events/Events';
import { Private } from '../Discussion/Private/Private';
import { CustomTab } from '../CustomTab';
import { GroupAccess } from './access/GroupAccess';
import { UserStatusProvider } from '../UserStatus/UserStatusProvider';
import { Tab } from '../../../../common/controllers/group-url';
import { MembersPage } from '../Members/MembersPage';

export interface GroupProps extends SiteNavigation {
  group: ApiTypesV1GroupResponse;
  canSeeGroup: boolean;
  activeTab: Tab;

  feedItemId: string;
  ready: boolean;
  apps: GroupAppsMap;
  className?: string;
  changeTab(tab: string): void;
  isRTL: boolean;
  isEditor: boolean;
  tabsUrls: TabsUrls;
}

export interface GroupState {}

type Props = GroupProps &
  WithAppDataProps &
  WithGroupProps &
  InjectedExperimentsProps &
  WithTranslation &
  WithAppToastsProps &
  WithTpaComponentsConfigProps &
  WithGroupActionProps &
  InjectedBiLoggerProps;

class GroupComponent extends React.Component<Props, GroupState> {
  private groupApps!: GroupApps;
  readonly ref = React.createRef<HTMLDivElement>();

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {};
  }

  private getGroupApps(): GroupApps {
    if (!this.props.apps) {
      return null as any;
    }
    if (!this.groupApps) {
      this.groupApps = GroupApps.fromAppsMap(this.props.apps);
    }
    return this.groupApps;
  }

  render() {
    const { ready, t, toasts, mobile, className, activeTab } = this.props;

    return (
      <ModalParentNodeRefProvider value={this.ref.current!}>
        <div
          ref={this.ref}
          className={st(classes.root, { mobile } as any, className)}
        >
          {ready ? (
            <>
              <AppToasts t={t} {...toasts} />
              <Breadcrumbs
                className={classes.breadcrumbs}
                items={this.getBreadCrumbs()}
              />
              <UserStatusProvider activeTab={activeTab}>
                <Header />
                {this.renderTabs()}
                {this.renderPages()}
              </UserStatusProvider>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </ModalParentNodeRefProvider>
    );
  }

  getBreadCrumbs(): { label: string; href?: string; action?(): void }[] {
    const { t, siteNavigation, group, navigateToLink } = this.props;
    const navLabels = [
      t(['groups-web.breadcrumbs.home', 'Home']),
      t('groups-web.breadcrumbs.group-list'),
      group && group.details && group.details.title
        ? group.details.title
        : t('groups-web.breadcrumbs.group-title-default'),
    ];
    return siteNavigation
      .filter((nav) => !!nav)
      .map((nav, i) => {
        return {
          label: navLabels[i],
          href: nav.href,
          action: nav.href
            ? null
            : () => {
                navigateToLink(nav.id);
              },
        } as any;
      });
  }

  private renderTabs() {
    const { activeTab, mobile, apps, isRTL } = this.props;
    const tabs = apps ? this.getTabs() : [];

    const activeTabIndex = apps
      ? Math.max(this.getVisibleTabs().indexOf(activeTab as Tab), 0)
      : 0;
    return (
      <div className={classes.tabsWrapper}>
        <Tabs
          data-hook={TABS_HOOK}
          activeTabIndex={activeTabIndex}
          alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
          variant={mobile ? TabsVariant.fullWidth : TabsVariant.fit}
          onTabClick={this.handleTabClick}
          items={tabs as any}
          className={classes.tabs}
        />
      </div>
    );
  }

  private renderProtectedPage(page: JSX.Element) {
    const { canSeeGroup, group } = this.props;
    if (canSeeGroup) {
      return page;
    }
    const { settings } = group;
    return <GroupAccess accessRestriction={settings?.accessRestriction!} />;
  }

  private renderPages() {
    const { activeTab, apps, feedItemId, group, mobile, tabsUrls } = this.props;
    const PAGES = GroupAppKey;
    const discussionUrl = tabsUrls && tabsUrls[Tab.DISCUSSION];

    let membersInstalled = false;
    let mediaInstalled = false;
    let eventsInstalled = false;
    let customTabInstalled = false;

    if (apps) {
      const memberApp = apps[GroupAppKey.MEMBERS_APP];
      membersInstalled = memberApp && memberApp.installed!;

      const mediaApp = apps[GroupAppKey.GALLERY_APP];
      mediaInstalled = mediaApp && mediaApp.installed!;

      const evetsApp = apps[GroupAppKey.EVENTS_APP];
      eventsInstalled = evetsApp && evetsApp.installed!;

      const customTabApp = apps[GroupAppKey.CUSTOM_APP];
      customTabInstalled = customTabApp?.installed!;
    }

    return (
      <Pages activePage={activeTab}>
        <Page tabId={`${PAGES.FEED_APP}-tab`} name={Tab.DISCUSSION}>
          {this.renderProtectedPage(
            <Discussion
              feedItemId={feedItemId}
              discussionUrl={discussionUrl}
            />,
          )}
        </Page>
        <Page
          tabId={`${PAGES.ABOUT_APP}-tab`}
          name={Tab.ABOUT}
          mobile={mobile}
          withSideGutters
        >
          <AboutPage />
        </Page>
        {membersInstalled && (
          <Page
            tabId={`${PAGES.MEMBERS_APP}-tab`}
            name={Tab.MEMBERS}
            mobile={mobile}
            withSideGutters
          >
            {this.renderProtectedPage(<MembersPage />)}
          </Page>
        )}
        {mediaInstalled && (
          <Page
            tabId={`${PAGES.GALLERY_APP}-tab`}
            name={Tab.MEDIA}
            mobile={mobile}
            withSideGutters
          >
            {this.renderProtectedPage(<Media />)}
          </Page>
        )}
        {eventsInstalled && (
          <Page
            tabId={`${PAGES.EVENTS_APP}`}
            name={Tab.EVENTS}
            mobile={mobile}
            withSideGutters
          >
            {this.renderProtectedPage(<Events groupId={group.groupId!} />)}
          </Page>
        )}
        {customTabInstalled && (
          <Page
            tabId={`${PAGES.CUSTOM_APP}`}
            name={Tab.CUSTOM}
            mobile={mobile}
            withSideGutters
          >
            <CustomTab />
          </Page>
        )}
      </Pages>
    );
  }

  private getTabs() {
    const { t, isEditor, tabsUrls } = this.props;
    const groupApps = this.getGroupApps();

    return groupApps
      .getInstalledApps()
      .filter((app) => !!GroupAppKeyToTab[app.key!])
      .map((app) => {
        const title = t(groupApps.getTranslationKey(app), { count: Infinity });
        const tabId = GroupAppKeyToTab[app.key!];
        const href = isEditor ? null : tabsUrls[tabId];

        return {
          title,
          href,
          id: `${tabId}-tab`,
        };
      })
      .filter((tab) => !!tab);
  }

  private getVisibleTabs() {
    const groupApps = this.getGroupApps();
    return groupApps && groupApps.getVisibleTabs();
  }

  handleTabClick = (selectedTabIndex: number) => {
    const tabs = this.getVisibleTabs();
    const tabId = tabs[selectedTabIndex];

    this.props.changeTab(tabId);
    this.props.bi.report(
      groupsTabClicked({
        group_id: this.props.group.groupId!,
        origin: 'tabs',
        name: tabId,
      }),
    );
  };
}

const enhance = compose(
  withTranslation(),
  withAppToasts,
  withExperiments,
  withTpaComponentsConfig,
  WithGroupActions,
  withBi,
  withAppData,
);

export const Group = enhance(GroupComponent) as React.ComponentType<GroupProps>;
