import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';

import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button } from '../../../../../common/components/Button/Button';
import { compose } from '../../../../../common/utils/compose';
import { Theme, withTheme } from '../../../../../common/context/theme';

interface DeletePostModalProps {
  isOpen: boolean;

  onClose(): void;

  onDelete(): void;
}

const DeletePostModalComponent: React.FC<
  DeletePostModalProps & WithTranslation & Theme
> = ({ isOpen, onClose, t, onDelete, forceBlackAndWhite }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.delete-post-popup.title')}
        subtitle={t('groups-web.discussion.delete-post-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button
          priority={ButtonPriority.secondary}
          onClick={onClose}
          bw={forceBlackAndWhite}
        >
          {t('groups-web.discussion.delete-post-popup.cancel')}
        </Button>
        <Button
          priority={ButtonPriority.primary}
          onClick={onDelete}
          bw={forceBlackAndWhite}
        >
          {t('groups-web.discussion.delete-post-popup.delete')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

const enhance = compose(withTranslation(), withTheme);

export const DeletePostModal = enhance(
  DeletePostModalComponent,
) as React.ComponentType<DeletePostModalProps>;
