import {
  I$WWrapper,
  IPubSub,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { BaseWidgetController } from '../BaseWidgetController';
import {
  APP_TOAST_EVENT,
  AppToast,
  AppToastsProps,
  AppToastTypes,
} from '@wix/social-groups-api';
import { generateId } from '../../utils/idGenerator';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PubSubObserver } from '../../../components/Group/controllers/pubSub/PubSubObserver';
import { PubSubEventTypes } from '../../../components/Group/controllers/pubSub/PubSubEventTypes';
import { UserRequestResponse } from '../../context/user/IUserContext';
import { RequestStatus } from '@wix/ambassador-social-groups-v2-join-group-request/types';

const APP_TOASTS_STORAGE_KEY = 'appToasts';

export class AppToastsController
  extends BaseWidgetController<{}>
  implements PubSubObserver {
  private toasts!: AppToast[];

  static emptyState: AppToastsProps = {
    entries: [],
    acknowledge: () => {},
    add: () => {},
  };

  static publish(pubSub: IPubSub, payload: Omit<AppToast, 'id' | 'at'>) {
    pubSub.publish(APP_TOAST_EVENT, payload, false);
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(controllerContext: ControllerParams) {
    super(controllerContext);
  }

  pageReady($w?: I$WWrapper, wixAPI?: IWixAPI) {
    this.toasts = this.getSavedToasts();
    this.setSubscriptions();
    this.updateToasts();
    return Promise.resolve();
  }

  acknowledge = (id: string): void => {
    const indexToDelete = this.getToastIndex(id);
    this.toasts.splice(indexToDelete, 1);
    this.updateAndSaveToasts();
  };

  setSubscriptions() {
    this.controllerConfig.platformAPIs.pubSub.subscribe(
      APP_TOAST_EVENT,
      ({ data }) => this.add(data),
      false,
    );
    this.controllerConfig.platformAPIs.pubSub.subscribe(
      PubSubEventTypes.JOIN_GROUP,
      ({ data }) => this.handleJoinGroupEvent(data),
      false,
    );
    this.controllerConfig.platformAPIs.pubSub.subscribe(
      PubSubEventTypes.SAVE_MEMBERSHIP_QUESTIONS,
      () =>
        this.add({
          type: AppToastTypes.MEMBERSHIP_QUESTIONS_SAVED,
        }),
      false,
    );
  }

  private readonly add = (data: any): void => {
    const newToast = {
      id: generateId(),
      at: Date.now(),
      ...data,
    };
    this.toasts.unshift(newToast);
    this.updateAndSaveToasts();
  };

  private readonly getToastIndex = (notificationId: string): number => {
    return this.toasts.findIndex(
      (notification) => notification.id === notificationId,
    );
  };

  private updateAndSaveToasts(): void {
    this.saveToasts();
    this.updateToasts();
  }

  private updateToasts(): void {
    this.setState({
      toasts: {
        entries: this.toasts,
        acknowledge: this.acknowledge,
        add: this.add,
      },
    });
  }

  private getSavedToasts(): AppToast[] {
    try {
      const item = this.getMemoryStorage().getItem(
        APP_TOASTS_STORAGE_KEY,
      ) as any;
      return JSON.parse(item) || [];
    } catch (e) {
      return [];
    }
  }

  private saveToasts(): void {
    this.getMemoryStorage().setItem(
      APP_TOASTS_STORAGE_KEY,
      JSON.stringify(this.toasts),
    );
  }

  private handleJoinGroupEvent({
    group,
    membershipResponse,
  }: UserRequestResponse) {
    if (!group || !membershipResponse) {
      return;
    }

    const membershipResponseToToastType = () => {
      if (membershipResponse.member) {
        return AppToastTypes.GROUP_JOINED;
      }
      if (
        membershipResponse.joinGroupRequest &&
        membershipResponse.joinGroupRequest.status === RequestStatus.PENDING
      ) {
        return AppToastTypes.GROUP_REQUEST_TO_JOIN;
      }
    };

    const type = membershipResponseToToastType();
    if (!type) {
      return;
    }

    return this.add({
      type: membershipResponseToToastType(),
      options: {
        'group-name': group.title,
      },
    });
  }
}
