import React from 'react';
import { ApiTypesV1QuestionAnswer } from '@wix/social-groups-api';

import { Divider, TPAComponentsConfig } from 'wix-ui-tpa';

import { QuestionAnswer } from './QuestionAnswer/QuestionAnswer';
import { TPAModalProps } from '../../../../../common/components/Modal/TPAModal';
import {
  ModalV2,
  ModalV2FooterProps,
} from '../../../../../common/components/Modal/ModalV2';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../../common/components/Spinner/Spinner';
import { compose } from '../../../../../common/utils/compose';
import { Theme, withTheme } from '../../../../../common/context/theme';

interface QuestionsAnswersModalComponentProps
  extends TPAModalProps,
    ModalV2FooterProps,
    Theme,
    TPAComponentsConfig {
  questionsAnswers: ApiTypesV1QuestionAnswer[];
  userName: string;
}

type QuestionsAnswersModalProps = QuestionsAnswersModalComponentProps &
  WithTranslation;

const QuestionsAnswersModalComponent: React.FC<QuestionsAnswersModalProps> = ({
  questionsAnswers,
  mobile,
  t,
  okButton,
  cancelButton,
  sideAction,
  userName,
  forceBlackAndWhite,
  ...modalProps
}) => {
  return (
    <ModalV2 {...modalProps}>
      <ModalV2.Title>
        {t('groups-web.membership-questions.answers-modal.title', {
          userName,
        })}
      </ModalV2.Title>
      <ModalV2.Content>
        {questionsAnswers ? (
          questionsAnswers.map((qa, index) => {
            return (
              <div key={index.toString()}>
                <QuestionAnswer qa={qa} index={index + 1} />
                {index !== questionsAnswers.length - 1 ? <Divider /> : null}
              </div>
            );
          })
        ) : (
          <Spinner offset="L" forceBlackAndWhite={forceBlackAndWhite} />
        )}
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={okButton}
        cancelButton={cancelButton}
        sideAction={sideAction}
      />
    </ModalV2>
  );
};

const enhance = compose(withTranslation(), withTheme);

export const QuestionsAnswersModal = enhance(
  QuestionsAnswersModalComponent,
) as React.ComponentType<QuestionsAnswersModalComponentProps>;
QuestionsAnswersModal.displayName = 'QuestionsAnswersModal';
