import { useMemo } from 'react';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { RicosTheme } from '@wix/ricos-common/dist/types';
import { getWixColor } from '@wix/yoshi-flow-editor/tpa-settings';
import { OOIHost } from '../../../../common/components/OOIComponent/OOIHost';
import { stylesParams } from '../../Settings/styles';

export function useRicosTheme(
  host: IHostProps,
  forceBlackAndWhite?: boolean,
  parentClass?: string,
) {
  const styles = useStyles();
  const postsTextColor = styles.get(stylesParams.postsTextColor);
  const postsBackgroundColor = styles.get(stylesParams.postsBackgroundColor);
  const postsTextFont = styles.get(stylesParams.postsTextFont);
  const mobilePostsTextFontSize = styles.get(
    stylesParams.mobilePostsTextFontSize,
  );

  const blackHex = '#000000';
  const whiteHex = '#ffffff';

  const theme = useMemo<RicosTheme>(() => {
    return {
      parentClass: parentClass || host.id,
      paletteConfig: {
        contentBgColor: false,
      },
      typography: {
        fontFamily: postsTextFont.family,
      },
      palette: {
        textColor: getTextColor(),
        bgColor: getBackgroundColor(),
        actionColor: getColor('color-8'),
        disabledColor: getColor('color-3'),
        fallbackColor: getColor5(),
        textColorLow: getColor5(),
      },
      customStyles: getCustomStyles(),
    };
  }, [
    postsTextColor,
    postsBackgroundColor,
    postsTextFont,
    forceBlackAndWhite,
    parentClass,
    host,
  ]);
  return { theme };

  function getCustomStyles() {
    const ooiHost = new OOIHost(host);

    const fontSize = `${
      ooiHost.isMobile() ? mobilePostsTextFontSize : postsTextFont.size
    }px`;

    return {
      p: {
        fontSize,
      },
      link: {
        fontSize,
      },
    };
  }

  function getTextColor(): string {
    if (forceBlackAndWhite) {
      return blackHex;
    }
    return postsTextColor.value;
  }

  function getBackgroundColor(): string {
    if (forceBlackAndWhite) {
      return whiteHex;
    }
    return postsBackgroundColor.value;
  }

  function getColor5(): string {
    if (forceBlackAndWhite) {
      return blackHex;
    }
    return getColor('color-5');
  }

  function getColor(reference: any) {
    return getWixColor({ colors: host.style.siteColors, reference })?.value;
  }
}
