import { ToolbarType, ViewerPlugin } from 'wix-rich-content-common';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import {
  LinkPreviewProviders,
  PluginMap,
  PRESETS,
  VerticalEmbedProviders,
  VideoProviders,
} from '../types';
import { Experiments } from '@wix/yoshi-flow-editor';
import { ExposeButtonsBuilder } from '../ExposeButtonsBuilder';

type Plugin = {
  name: PluginTypes;
  enabledByExperiments?: string[];
};

const postEditorPlugins: Plugin[] = [
  { name: PluginTypes.Image },
  { name: PluginTypes.Gallery },
  { name: PluginTypes.Video },
  { name: PluginTypes.Giphy },
  { name: PluginTypes.FileUpload },
  { name: PluginTypes.Emoji },
  { name: PluginTypes.Mention },
  { name: PluginTypes.ExternalLink },
  { name: PluginTypes.Divider },
  { name: PluginTypes.Poll },
  {
    name: PluginTypes.LinkButton,
    enabledByExperiments: ['specs.groups.EnableRCEButtonPlugin'],
  },
  {
    name: PluginTypes.VerticalEmbed,
    enabledByExperiments: [
      'specs.groups.EnableRCEVerticalEmbedBookingsPlugin',
      'specs.groups.EnableRCEVerticalEmbedEventsPlugin',
      'specs.groups.EnableRCEVerticalEmbedStoresPlugin',
    ],
  },
  // TODO: enable after adding google api key
  // https://wix.slack.com/archives/C8QHV6UM9/p1650524580286789
  // {
  //   name: PluginTypes.Map,
  //   enabledByExperiments: ['specs.groups.EnableRCEMapPlugin']
  // },
  // TODO: enable after ricos fix
  // PluginTypes.History,
  { name: PluginTypes.LinkPreview },
  {
    name: PluginTypes.HTML,
    enabledByExperiments: ['specs.groups.EnableRCEHtmlPlugin'],
  },
  {
    name: PluginTypes.TextColor,
    enabledByExperiments: ['specs.groups.EnableRCETextColorPlugin'],
  },
  {
    name: PluginTypes.TextHighlight,
    enabledByExperiments: ['specs.groups.EnableRCETextHighlightColorPlugin'],
  },
  {
    name: PluginTypes.Headings,
    enabledByExperiments: ['specs.groups.EnableRCEHeadingsPlugin'],
  },
  {
    name: PluginTypes.Spoiler,
    enabledByExperiments: ['specs.groups.EnableRCESpoilerPlugin'],
  },
  {
    name: PluginTypes.LineSpacing,
    enabledByExperiments: ['specs.groups.EnableRCELineSpacingFormatting'],
  },
];

const presets: { [key in PRESETS]: Plugin[] } = {
  [PRESETS.EDITOR]: postEditorPlugins,
  [PRESETS.VIEWER]: [
    { name: PluginTypes.Image },
    { name: PluginTypes.Gallery },
    { name: PluginTypes.Video },
    { name: PluginTypes.Giphy },
    { name: PluginTypes.FileUpload },
    { name: PluginTypes.Emoji },
    { name: PluginTypes.Poll },
    { name: PluginTypes.Mention },
    { name: PluginTypes.ExternalLink },
    { name: PluginTypes.LinkPreview },
    { name: PluginTypes.LinkButton },
    { name: PluginTypes.VerticalEmbed },
    { name: PluginTypes.TextColor },
    { name: PluginTypes.TextHighlight },
    { name: PluginTypes.Headings },
    { name: PluginTypes.Divider },
    { name: PluginTypes.Spoiler },
    { name: PluginTypes.Map },
    { name: PluginTypes.HTML },
    { name: PluginTypes.LineSpacing },
  ],
};

const pluginsToDisplayInMorePlusFooterToolbar: PluginTypes[] = [
  PluginTypes.LinkButton,
  PluginTypes.Map,
  PluginTypes.VerticalEmbed,
  PluginTypes.LinkPreview,
  PluginTypes.HTML,
];

export type PluginSelectorOptions = {
  pluginMap: PluginMap;
  preset: PRESETS;
  helpers: any;
};

export class PluginSelector {
  public plugins: ViewerPlugin[];
  public pluginsToDisplayInFooterToolbar: PluginTypes[];
  private experiments: Experiments;

  constructor(
    private pluginMap: PluginMap,
    private preset: PRESETS,
    protected helpers: any,
  ) {
    this.experiments = helpers.experiments;
    const config = this.getPluginsConfig();
    this.plugins = this.getAllAvailablePlugins().map((plugin) => {
      // @ts-expect-error
      return pluginMap[plugin.name](config[plugin.name]);
    });
    this.pluginsToDisplayInFooterToolbar = this.getPluginsToDisplayInFooterToolbar();
  }

  public getPluginsToDisplayInFooterToolbar(): PluginTypes[] {
    if (
      this.experiments.enabled('specs.groups.EnableRCEMorePlusFooterSection')
    ) {
      return this.getAllAvailablePlugins()
        .filter(
          (plugin) =>
            !pluginsToDisplayInMorePlusFooterToolbar.includes(plugin.name),
        )
        .map((plugin) => plugin.name);
    }
    return [];
  }

  private getAllAvailablePlugins(): Plugin[] {
    return presets[this.preset].filter(
      (plugin) => this.isPluginEnabled(plugin) && this.pluginMap[plugin.name],
    );
  }

  private isPluginEnabled(plugin: Plugin): boolean {
    if (plugin.enabledByExperiments) {
      return plugin.enabledByExperiments.some((experiment) =>
        this.experiments.enabled(experiment),
      );
    }
    return true;
  }

  private getPluginsConfig() {
    return {
      [PluginTypes.Giphy]: {
        insertToolbars: [ToolbarType.SIDE, ToolbarType.FOOTER],
        toolbar: {
          hidden: ['separator1'],
        },
      },
      [PluginTypes.Image]: {
        toolbar: {
          hidden: ['separator1'],
        },
        disableExpand: false,
      },
      [PluginTypes.Poll]: {
        ...this.helpers[PluginTypes.Poll],
        voteRole: 'SITE_MEMBERS',
        showVoteRoleSetting: false,
      },
      [PluginTypes.Gallery]: {
        accept: 'image/*',
        toolbar: {
          hidden: ['separator2'],
        },
      },
      [PluginTypes.FileUpload]: {
        toolbar: {
          hidden: ['separator', 'separator1', 'separator2', 'separator3'],
        },
        downloadTarget: '_blank',
      },
      [PluginTypes.Video]: {
        toolbar: {
          hidden: ['separator1'],
        },
        exposeButtons: this.getVideoExposeButtons(),
      },
      [PluginTypes.Mention]: {
        ...this.helpers[PluginTypes.Mention],
      },
      [PluginTypes.LinkPreview]: {
        exposeEmbedButtons: this.getLinkPreviewExposeButtons(),
        enableEmbed: true,
        enableLinkPreview: true,
      },
      [PluginTypes.Emoji]: {},
      [PluginTypes.ExternalLink]: {},
      [PluginTypes.VerticalEmbed]: {
        exposeEmbedButtons: this.getVerticalEmbedExposeButtons(),
      },
      [PluginTypes.Spoiler]: {
        ...this.helpers[PluginTypes.Spoiler],
      },
    };
  }

  private getLinkPreviewExposeButtons(): string[] {
    const buttonsBuilder = new ExposeButtonsBuilder(this.experiments);
    return buttonsBuilder
      .add(
        LinkPreviewProviders.Instagram,
        'specs.groups.EnableRCELinkPreviewInstagramPlugin',
      )
      .add(
        LinkPreviewProviders.Twitter,
        'specs.groups.EnableRCELinkPreviewTwitterPlugin',
      )
      .add(
        LinkPreviewProviders.YouTube,
        'specs.groups.EnableRCELinkPreviewYoutubePlugin',
      )
      .add(
        LinkPreviewProviders.TikTok,
        'specs.groups.EnableRCELinkPreviewTikTokPlugin',
      )
      .build();
  }

  private getVideoExposeButtons(): string[] {
    const buttonsBuilder = new ExposeButtonsBuilder(this.experiments);
    return buttonsBuilder
      .add(VideoProviders.Video)
      .add(VideoProviders.SoundCloud, 'specs.groups.EnableRCESoundCloudPlugin')
      .build();
  }

  private getVerticalEmbedExposeButtons(): string[] {
    const buttonsBuilder = new ExposeButtonsBuilder(this.experiments);
    return buttonsBuilder
      .add(
        VerticalEmbedProviders.Event,
        'specs.groups.EnableRCEVerticalEmbedEventsPlugin',
      )
      .add(
        VerticalEmbedProviders.Booking,
        'specs.groups.EnableRCEVerticalEmbedBookingsPlugin',
      )
      .add(
        VerticalEmbedProviders.Product,
        'specs.groups.EnableRCEVerticalEmbedStoresPlugin',
      )
      .build();
  }
}
