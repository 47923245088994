import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ModalProps, Modal } from '../../Modal';
import { Button } from '../../Button';
import { useUser } from '../../../context/user/useUser';
import { useTheme } from '../../../context/theme/useTheme';
import { usePaidPlansBi } from 'common/hooks/usePaidPlansBi';

type Props = ModalProps;

export const PlansDialog: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const { userActions, userRequestResponse } = useUser();
  const theme = useTheme();
  const bi = usePaidPlansBi();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      withCloseButton={true}
    >
      <Modal.Header
        title={t('groups-web.restriction.plans.dialog.title')}
        subtitle={t('groups-web.restriction.plans.dialog.subtitle')}
      />
      <Modal.Buttons>
        <Button
          bw={theme.forceBlackAndWhite}
          priority={ButtonPriority.basic}
          onClick={() => {
            bi.getPlanClick();
            userActions.pickPricingPlan(userRequestResponse!);
          }}
        >
          {t('groups-web.restriction.plans.dialog.cta')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

PlansDialog.displayName = 'PlansDialog';
