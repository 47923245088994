import React from 'react';

import {
  Text,
  TextTypography,
  Tag,
  TagSize,
  HeroImage,
  ImageResizeOptions,
  ImageLoadingBehaviorOptions,
} from 'wix-ui-tpa';

import {
  ApiTypesV1GroupMemberResponse,
  isGroupSecret,
  isMember,
  memberWrapper,
  parseImageName,
  ReactionType,
} from '@wix/social-groups-api';

import { getCommentIdFromLocation } from '../Comments/getCommentId';
import { AuthorInfo } from '../AuthorInfo';
import { PostActions } from '../PostActions';

import { getActivityBody } from '../ActivityPost/ActivityBody';

// STYLES
import { st, classes } from './FeedItem.st.css';

import { FEED_ITEM } from '../dataHooks';
import { SiteMembers } from '../../../controllers/members/SiteMembers';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/yoshi-flow-editor';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { Box } from '../../../../../common/components/Box/Box';
import { ShareButton } from '../../Share/ShareButton';
import { RichContentViewer } from '../../RichContent/Viewer';
import { Spinner } from '../../../../../common/components/Spinner';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { compose } from '../../../../../common/utils/compose';
import { EFilterKeys } from '../../../types/EFilterKeys';
import {
  FeedItemActivityType,
  IFeedItem,
  IFeedItemActivity,
  IFeedItemEntity,
} from '../../../types/IFeedItem';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../Settings/settingsParams';
import { groupFeedTopicsSelectTopicInFeed } from '@wix/bi-logger-groups/v2';
import { getParam } from '../../../../../common/utils/searchParams';
import { IUserContext } from 'common/context/user/IUserContext';
import { withUser } from 'common/context/user/withUser';

// TODO: join Reactions & ReactedMembers
const Reactions = React.lazy(() =>
  import(/* webpackChunkName: "reactions" */ '../Reactions/Reactions').catch(
    (e) => {
      return { default: () => null };
    },
  ),
);

const ReactedMembers = React.lazy(() =>
  import(
    /* webpackChunkName: "reactedMembers" */ '../Reactions/ReactedMembers/ReactedMembers'
  ).catch((e) => {
    return { default: () => null };
  }),
);

const Comments = React.lazy(() =>
  import(/* webpackChunkName: "comments" */ '../Comments/new/Comments')
    .then((module) => ({ default: module.Comments }))
    .catch((e) => {
      return { default: () => null } as any;
    }),
);

export interface FeedItemProps {
  feedItem: IFeedItem;
  siteMembersMap: SiteMembers['siteMembersMap'];
  currentMember: ApiTypesV1GroupMemberResponse;
  contextToken: string;
  showSeeMore?: boolean;

  onDeletePostClick(feedItemId: string): void;

  onPinPostClick(feedItemId: string): void;

  onUnpinPostClick(feedItemId: string): void;

  onFollowPostClick(feedItemId: string): void;

  onUnfollowPostClick(feedItemId: string): void;

  onUpdatePostClick(feedItemId: string, entity: any, topicId?: string): void;

  react(feedItemId: string, reaction: ReactionType): void;

  unreact(feedItemId: string, reactionCode: string): void;

  onShare(): void;
}

interface FeedItemComponentState {
  totalComments: number;
  showComments: boolean;
  didMount: boolean;
  contentState: RawDraftContentState<any>;
}

type Props = FeedItemProps &
  WithTranslation &
  WithTpaComponentsConfigProps &
  WithSettingsProps &
  WithSiteMembers &
  WithGroupProps &
  InjectedBiLoggerProps &
  InjectedExperimentsProps &
  IUserContext;

export class FeedItemComponent extends React.Component<
  Props,
  FeedItemComponentState
> {
  constructor(props: Readonly<Props>) {
    super(props);

    let totalComments: number = 0;
    try {
      totalComments = props.feedItem.comments.total;
    } catch (e) {}

    this.state = {
      didMount: false,
      totalComments,
      showComments: this.showComments(),
      contentState: this.getContentState(),
    } as any;
  }

  private showComments() {
    const { mobile } = this.props;
    const commentIdFromLocation = getCommentIdFromLocation();
    const showComments = !!commentIdFromLocation || !mobile;
    return showComments && !getParam('hideComments');
  }

  componentDidMount() {
    this.setState({ didMount: true });
  }

  private getContentState() {
    const { activity, entity } = this.props.feedItem;

    if (activity) {
      return getActivityBody(activity);
    }

    if (!entity) {
      return null;
    }

    return ContentConverter.parseContentString(entity.body.content);
  }

  handleReact = (reaction: ReactionType) => {
    this.props.react(this.props.feedItem.feedItemId, reaction);
  };

  handleUnreact = (reactionCode: string) => {
    this.props.unreact(this.props.feedItem.feedItemId, reactionCode);
  };

  toggleComments = () =>
    this.setState({ showComments: !this.state.showComments });

  render() {
    const {
      settings,
      feedItem,
      t,
      onDeletePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onShare,
      mobile,
      group,
      userActions,
    } = this.props;

    const { contentState } = this.state;

    const { feedItemId, activity, entity } = feedItem;

    const [topic] = entity ? entity.topics : [];

    const { name, imageUrl, role, siteMemberId } = feedItem.createdBy;

    const showShareButton =
      settings.get(settingsParams.showShareButton) && !isGroupSecret(group);
    return (
      <Box
        className={st(classes.root, {
          mobile,
          withReactions: feedItem.reactions.total > 0,
        } as any)}
        data-hook={FEED_ITEM}
        withSideBorders={!mobile}
      >
        <div className={classes.meta}>
          <AuthorInfo
            name={name || t('groups-web.member.anonymous')}
            avatarUrl={imageUrl}
            activity={activity}
            timeStamp={feedItem.createdAt}
            relationship="JOINED"
            roles={[role?.value]}
            badges={this.getBadges(feedItem.createdBy.siteMemberId!)}
            onClick={() => userActions.openUserProfile(siteMemberId!)}
          />
          {showShareButton && (
            <ShareButton className={classes.shareButton} onClick={onShare} />
          )}
          <PostActions
            itemId={feedItemId}
            topicId={topic?.id}
            showShareButton={showShareButton}
            isActivityPost={!!activity}
            isPinnedPost={!!feedItem.pin}
            isFollowedPost={
              !!feedItem.requesterContext &&
              !!feedItem.requesterContext.subscribed
            }
            createdBy={feedItem.createdBy}
            deletePost={() => onDeletePostClick(feedItemId)}
            updatePost={this.updatePost}
            pinPost={() => {
              onPinPostClick(feedItemId);
            }}
            unpinPost={() => {
              onUnpinPostClick(feedItemId);
            }}
            followPost={() => {
              onFollowPostClick(feedItemId);
            }}
            unfollowPost={() => {
              onUnfollowPostClick(feedItemId);
            }}
            contentState={contentState}
            onShare={onShare}
            iconClassName={classes.threeDotsIcon}
          />
        </div>
        {this.renderContent()}
        {this.renderPostTopics()}
        {this.renderReactions()}
        {this.renderComments()}
      </Box>
    );
  }

  renderContent() {
    const feedItemActivity = this.props.feedItem.activity;
    const { contentState } = this.state;

    if (contentState) {
      return (
        <RichContentViewer
          showSeeMore={this.props.showSeeMore}
          content={contentState}
          usage="FeedItem"
          contentId={this.props.feedItem.feedItemId}
        />
      );
    }

    if (!feedItemActivity) {
      return null;
    }

    switch (feedItemActivity.activityType) {
      case FeedItemActivityType.GROUP_COVER_CHANGED:
        return (
          <HeroImage
            fluid
            aspectRatio={this.props.mobile ? 1 : 16 / 9}
            resize={ImageResizeOptions.cover}
            loadingBehavior={ImageLoadingBehaviorOptions.blur}
            className={classes.activityImage}
            src={parseImageName(feedItemActivity.data.src)}
          />
        );

      default:
        return null;
    }
  }

  private readonly updatePost = (
    contentState: RawDraftContentState<any>,
    topicId: string,
  ) => {
    // optimistic update
    this.setState({ contentState });
    const { onUpdatePostClick, feedItem } = this.props;
    onUpdatePostClick(feedItem.feedItemId, contentState, topicId);
  };

  async componentDidUpdate(props: Readonly<Props>) {
    if (
      props.feedItem.updatedAt?.toString() !==
      this.props.feedItem.updatedAt?.toString()
    ) {
      const contentState = this.getContentState();
      // real update
      this.setState({ contentState } as any);
    }
  }

  private renderComments() {
    const { feedItem, group } = this.props;

    const { showComments, didMount } = this.state;

    const renderComments = showComments && didMount;
    return renderComments /* TODO: Comments are not ready for SSR */ ? (
      <React.Suspense fallback={<Spinner />}>
        <Comments
          feedItemId={feedItem.feedItemId}
          groupId={group.groupId}
          isGroupMember={isMember(group)}
        />
      </React.Suspense>
    ) : null;
  }

  protected renderPostTopics() {
    const {
      feedItem,
      feed: { feedTopics },
    } = this.props;

    if (
      !feedItem.entity ||
      !feedItem.entity.topics ||
      !feedItem.entity.topics.length
    ) {
      return null;
    }

    const [topic] = feedItem.entity.topics;

    if (!topic) {
      return null;
    }

    return (
      <Tag
        size={TagSize.small}
        className={classes.postTopic}
        onClick={() => this.filterByTopic(topic.id!)}
      >
        {topic.displayName}
      </Tag>
    );
  }

  filterByTopic(topicId: string) {
    const {
      feedItem,
      bi,
      group: { groupId },
      feed: { applyFeedFilters },
    } = this.props;

    applyFeedFilters({ [EFilterKeys.TOPICS]: topicId });
    bi.report(
      groupFeedTopicsSelectTopicInFeed({
        groupId: groupId!,
        postId: feedItem.feedItemId,
        userEntry: BIUserEntry.SITE,
        origin: 'feed_topic_in_post',
      }),
    );
  }

  private renderReactions() {
    const { didMount } = this.state;
    if (!didMount) {
      return null;
    }
    const { feedItem, settings } = this.props;

    const showReactions = settings.get(settingsParams.showReactions);

    const hasReactions = !!feedItem.reactions.usersReacted.users.length;
    return (
      <React.Suspense fallback={<Spinner />}>
        <div className={classes.reactionsAndMeta}>
          {showReactions && (
            <div className={classes.reactions}>
              <Reactions
                reactions={feedItem.reactions}
                react={this.handleReact}
                unreact={this.handleUnreact}
              />

              {!hasReactions && this.renderCommentsCounter()}
            </div>
          )}

          {(hasReactions || !showReactions) && (
            <div className={classes.meta}>
              <ReactedMembers reactions={feedItem.reactions} />
              {this.renderCommentsCounter()}
            </div>
          )}
        </div>
      </React.Suspense>
    );
  }

  private renderCommentsCounter() {
    return (
      <span onClick={this.toggleComments}>
        <Text
          className={classes.totalComments}
          typography={TextTypography.listText}
        >
          {this.props.t('groups-web.discussion.feed.total-comments', {
            count: this.getCommentsCount(),
          })}
        </Text>
      </span>
    );
  }

  private getCommentsCount() {
    try {
      return this.props.feedItem.comments.total;
    } catch (e) {
      console.log('[FeedItemComponent.getCommentsCount] Error');
      return 0;
    }
  }

  private readonly getBadges = (siteMemberId: string): string[] => {
    const { badges, membersBadgeIds } = this.props;
    if (!badges || !membersBadgeIds) {
      return [];
    }
    return (membersBadgeIds[siteMemberId] || [])
      .map((badgeId) => {
        const badge = badges[badgeId];
        if (!badge) {
          return '';
        }
        return badge.title!;
      })
      .filter((badgeTitle) => !!badgeTitle);
  };
}

const enhance = compose(
  withTranslation(),
  withTpaComponentsConfig,
  withSettings,
  WithGroup,
  withSiteMembers,
  withExperiments,
  withBi,
  withUser,
);

export const FeedItem = enhance(
  FeedItemComponent,
) as React.ComponentType<FeedItemProps>;

FeedItem.displayName = 'FeedItem';
