import { Experiments } from '@wix/yoshi-flow-editor';

export class ExposeButtonsBuilder {
  private buttons: Set<string>;
  constructor(private experiments: Experiments, initialState: string[] = []) {
    this.buttons = new Set(initialState);
  }

  add(button: string, experimentName?: string): ExposeButtonsBuilder {
    if (
      (experimentName && this.experiments.enabled(experimentName)) ||
      !experimentName
    ) {
      this.buttons.add(button);
    }
    return this;
  }

  delete(button: string, experimentName?: string): ExposeButtonsBuilder {
    if (
      (experimentName && this.experiments.enabled(experimentName)) ||
      !experimentName
    ) {
      this.buttons.delete(button);
    }
    return this;
  }

  build(): string[] {
    return Array.from(this.buttons);
  }
}
