import React from 'react';
import { debounce, partition } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import {
  ApiTypesV1GroupMemberResponse,
  ReactionType,
} from '@wix/social-groups-api';

import { FeedItem } from '../FeedItem';
import { PinnedPost } from '../PinnedPost';

import { UIError } from '../../Error/UIError';
import { SomethingWentWrong } from '../SomethingWentWrong/SomethingWentWrong';
import { getShareUrl } from '../../Share/getShareUrl';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import { Loggers } from '../../../contexts/logger/Loggers';
import {
  withAppData,
  WithAppDataProps,
} from '../../../contexts/AppData/withAppData';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';

import { ErrorOrigin } from '../../../controllers/errorHandler/IErrorEvent';
import { ErrorBoundary } from '../../../../../common/ErrorBoundary/ErrorBoundary';
import { Spinner } from '../../../../../common/components/Spinner';
import { ShareModal } from '../../../../../common/components/ShareModal/ShareModal';
import { compose } from '../../../../../common/utils/compose';
import { withLogger } from '../../../contexts/logger/withLogger';
import { IFeedItem } from '../../../types/IFeedItem';

import WixCommentsTranslationsNoI18nextProvider from '@wix/comments-ooi-translations-provider/no-i18next-provider';
import { FEED_LIST } from 'Group/Widget/Discussion/dataHooks';

interface FeedListProps {
  hasMore: boolean;
  feedItems: IFeedItem[];
  currentMember: ApiTypesV1GroupMemberResponse;
  fetchMore(): void;
  contextToken: string;
  showItemSeeMore: boolean;
}

export interface FeedProps extends FeedListProps {
  // onCreatePostClick(): void;
  onDeletePostClick(feedItemId: string): void;
  onPinPostClick(feedItemId: string): void;
  onUnpinPostClick(feedItemId: string): void;
  onFollowPostClick(feedItemId: string): void;
  onUnfollowPostClick(feedItemId: string): void;
  onUpdatePostClick(feedItemId: string, entity: any, topicId?: string): void;
  react(feedItemId: string, reaction: ReactionType): void;
  unreact(feedItemId: string, reactionCode: string): void;
  discussionUrl?: string;
}

type Props = FeedProps &
  WithSiteMembers &
  Loggers &
  WithGroupProps &
  WithAppDataProps &
  WithTranslation;

type ListProps = Partial<Props> & {
  onShare(feedItemId: string): void;
};

const FeedList = ({
  currentMember,
  feedItems,
  siteMembersMap,
  onDeletePostClick,
  onPinPostClick,
  onUnpinPostClick,
  onFollowPostClick,
  onUnfollowPostClick,
  onUpdatePostClick,
  react,
  unreact,
  onShare,
  contextToken,
  showItemSeeMore,
}: ListProps) => {
  return (
    <div data-hook={FEED_LIST}>
      {feedItems!.map((feedItem) => (
        <FeedItem
          key={feedItem.feedItemId}
          feedItem={feedItem}
          showSeeMore={showItemSeeMore}
          onDeletePostClick={onDeletePostClick!}
          onPinPostClick={onPinPostClick!}
          onUnpinPostClick={onUnpinPostClick!}
          onFollowPostClick={onFollowPostClick!}
          onUnfollowPostClick={onUnfollowPostClick!}
          onUpdatePostClick={onUpdatePostClick!}
          siteMembersMap={siteMembersMap!}
          currentMember={currentMember!}
          react={react!}
          unreact={unreact!}
          onShare={() => onShare(feedItem.feedItemId)}
          contextToken={contextToken!}
        />
      ))}
    </div>
  );
};

interface FeedState {
  shareItemId: string;
}

export class FeedComponent extends React.Component<Props> {
  state: FeedState = {
    shareItemId: null as any,
  };

  fetchMore = debounce(this.fetchMorePure, 200).bind(this);

  fetchMorePure() {
    this.props.fetchMore();
  }

  render() {
    const {
      t,
      currentMember,
      hasMore,
      feedItems,
      onDeletePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onUpdatePostClick,
      siteMembersMap,
      logger,
      react,
      unreact,
      contextToken,
      showItemSeeMore,
    } = this.props;
    const [pinnedFeedItems, notPinnedFeedItems] = partition(
      feedItems,
      this.isPinnedPost,
    );
    const pinnedPost = pinnedFeedItems[0];

    const postActions = {
      onDeletePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onUpdatePostClick,
      react,
      unreact,
    };

    const isGroupOwner = false;
    return (
      <UIError origin={ErrorOrigin.Feed} isOwner={isGroupOwner!}>
        <ErrorBoundary
          loggers={[logger]}
          errorComponent={<SomethingWentWrong isOwner={isGroupOwner!} />}
        >
          <WixCommentsTranslationsNoI18nextProvider>
            {pinnedPost ? (
              <PinnedPost
                showSeeMore={true}
                currentMember={currentMember}
                siteMembersMap={siteMembersMap}
                feedItem={pinnedPost}
                onShare={() => this.onShare(pinnedPost.feedItemId)}
                contextToken={contextToken}
                {...postActions}
              />
            ) : null}

            <InfiniteScroll
              pageStart={0}
              hasMore={hasMore}
              initialLoad={false}
              loadMore={this.fetchMore}
              loader={
                <Spinner
                  key="Spinner"
                  offset="M"
                  label={t('groups-web.loading')}
                />
              }
            >
              <FeedList
                {...postActions}
                key="FeedList"
                currentMember={currentMember}
                siteMembersMap={siteMembersMap}
                feedItems={notPinnedFeedItems}
                onShare={this.onShare}
                contextToken={contextToken}
                showItemSeeMore={showItemSeeMore}
              />
            </InfiniteScroll>
          </WixCommentsTranslationsNoI18nextProvider>
        </ErrorBoundary>
        <ShareModal
          isOpen={!!this.state.shareItemId}
          onRequestClose={this.closeShareDialog}
          title={t('groups-web.discussion.feed.post-actions.share.dialog')}
          shareUrl={this.getSharePostUrl(this.state.shareItemId)}
        />
      </UIError>
    );
  }

  private readonly onShare = (shareItemId: string) =>
    this.setState({ shareItemId });

  private readonly closeShareDialog = () =>
    this.setState({ shareItemId: null });

  private getSharePostUrl(itemId: string) {
    if (this.props.discussionUrl) {
      return `${this.props.discussionUrl}/${itemId}`;
    }
    return `${getShareUrl()}/${itemId}`;
  }

  private isPinnedPost(feedItem: IFeedItem) {
    return feedItem.pin;
  }
}
const enhance = compose(
  withTranslation(),
  withLogger,
  withSiteMembers,
  WithGroup,
  withAppData,
);
export const Feed = enhance(FeedComponent) as React.ComponentType<FeedProps>;
Feed.displayName = 'Feed';
