import React from 'react';
import { Theme } from './Theme';
import { ButtonType } from 'common/settings/groups-list/settingsConstants';

export const ThemeContext = React.createContext<Theme>({
  forceBlackAndWhite: false,
  buttonType: ButtonType.rectangle,
});

ThemeContext.displayName = 'ThemeContext';
export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
