import React, { useContext, useMemo, useState } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { GroupAppKey, isAdmin, Tab } from '@wix/social-groups-api';

import { Viewer } from './Viewer';
import { Editor } from './Editor';

import { classes } from './CustomTab.st.css';

import { GroupContext } from '../../contexts/Group/GroupContext';
import { GroupActionsContext } from '../../contexts/GroupActions/GroupActionsContext';
import { useBi } from '@wix/yoshi-flow-editor';
import { Box } from '../../../../common/components/Box/Box';
import { getCustomTabBIData } from './getCustomTabBIData';
import {
  groupAddCustomTabContent,
  groupCustomTabSave,
} from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { RawDraftContentState } from '../../../../common/ContentEditor/types';
import { Origins } from '../../../../common/bi-logger/customTab/origins';

export const CustomTab = () => {
  const { apps, group } = useContext(GroupContext);
  const { configureApps } = useContext(GroupActionsContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const bi = useBi();
  const customTabApp = apps[GroupAppKey.CUSTOM_APP];
  const canEdit = useMemo(() => isAdmin(group), [group.relationship]);
  const contentState = useMemo(() => {
    try {
      return JSON.parse(customTabApp.customSource!);
    } catch (error) {
      return null;
    }
  }, [customTabApp.customSource]);

  return (
    <Box article>
      <SwitchTransition>
        <CSSTransition
          timeout={300}
          classNames={classes as CSSTransitionClassNames}
          key={isEditMode ? 'editor' : 'viewer'}
        >
          {isEditMode ? (
            <Editor
              contentState={contentState}
              handleCancelClick={toggleViewMode}
              handleSubmitClick={handleSubmit}
            />
          ) : (
            <Viewer
              canEdit={canEdit}
              contentState={contentState}
              handleEditClick={toggleEditMode}
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </Box>
  );

  async function handleSubmit(_contentState: RawDraftContentState<any>) {
    bi.report(
      groupCustomTabSave({
        groupId: group.groupId!,
        userEntry: BIUserEntry.SITE,
        tabName: customTabApp.customName || Tab.CUSTOM,
        ...getCustomTabBIData(_contentState),
      }),
    );
    await configureApps([
      {
        installed: true,
        key: customTabApp.key,
        customName: customTabApp.customName,
        customSource: JSON.stringify(_contentState),
      },
    ]);
    return;
  }

  function toggleEditMode(origin: Origins) {
    setIsEditMode(true);
    bi.report(
      groupAddCustomTabContent({
        origin,
        groupId: group.groupId!,
        userEntry: BIUserEntry.SITE,

        isContentEmpty: origin === Origins.EMPTY_STATE ? 'true' : 'false',
      }),
    );
  }

  function toggleViewMode() {
    setIsEditMode(false);
  }
};

CustomTab.displayName = 'CustomTab';
